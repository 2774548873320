import React, { Component } from 'react';
import {Form, Breadcrumb, Button, Image} from 'react-bootstrap';
import 'moment/locale/id.js';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        note : '',
        original_name : '',
        upload_file : '',
    }
  }


  handleUpload= event => {

    event.preventDefault();

    if(this.state.original_name ===''){
      global.swal("Error", "Harap pilih file gambar", "info")
    }else{

      global.swal({
        text: 'Apakah anda yakin akan diupload?',
        backgroundColor: 'blue',
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: [
          'Cancel',
          {text: 'Upload', 
          closeModal: false,}
        ],
      })
      .then((confirm) => {
        let errorMessage =  { code : 403, message : 'cancel' };
        if (!confirm) throw errorMessage;
        
        const data = new FormData()
        data.append('upload_file', this.state.selectedFile)
        data.append('student_id', localStorage.getItem('DATA_JALUR').split('-')[0])
        data.append('original_name', this.state.original_name)
        data.append('note', this.state.note)

        return fetch(global.variable.LINK_API + 'uniform/upload',
        {
          method: 'post',
          headers: {
              Accept: "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'),
          },
          body: data, 
        })
      })
      .then(results => {
        return results.json();
      })
      .then(resData => {
        if(resData.message==="Success"){
          console.log(resData);
          global.swal("Success", 'Sukses Upload', "success")
          .then((value) => {
            window.location.href="/uploadbuktiseragamdetail";
          });
        }
        
      })
      .catch(err => {
        console.log(err)
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

    }
  
  };

  getFiles(files){
    if(files[0].type==="image/jpeg" || files[0].type === "image/png"){
      if(files[0].size.split(' ')[0] > 2048){
        global.swal("Error", "Gambar tidak boleh lebih dari 2 MB", "info")
      }
      else{
        this.setState({ encrypt_64: files[0].base64, original_name: files[0].name,  })
      }
    }else{
      global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png)", "info")
    }
  }

  onChangeHandler=event=>{
    var file = event.target.files[0];

    if(file.size>2000000){
      global.swal("Error", "File tidak boleh lebih dari 2 MB", "info")
      this.setState({ selectedFile: '', original_name: '',  })
    }else if(file.type==="image/png"||file.type==="image/jpeg"||file.type==="application/pdf"){
      console.log(file);
       this.setState({selectedFile: file, original_name : file.name});
    }else{
      global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png) atau pdf", "info")
      this.setState({ selectedFile: '', original_name: '',  })
    }
 
  }

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0], original_name : '', upload_file : ''});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        //this.setState({note_verification : resData.data[0].note_verification});
        this.setState({is_show : true});
        console.log(this.state);
      }

     
      
    })

    fetch(global.variable.LINK_API + 'uniform/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState({uniform : resData.data});
        this.setState({is_showdata : true});
        
      }
     
      //console.log(resData);
      console.log(this.state.uniform);
      console.log(localStorage.getItem('uniform1'));
       
    })

    
  }
    
  

  runswal(){
    var span = document.createElement("span");
    //span.innerHTML = "Terima kasih anda telah mendaftar ke SMP DEMO Semarang. \n\nNomor Pendaftaran anda adalah " + resData.data.year + '-' + resData.data.registration_type_id  + '-' +  resData.data.registration_number +"\n\n<strong>Silahkan melakukan transfer biaya pendaftaran sebesar Rp.100.000 ke rekening Bank Mandiri no 136-00-0149000-9 atas nama SMP PANGUDI LUHUR DOMENICO SAVIO SMG dengan mencantumkan nomor pendaftaran anda pada kolom catatan.</strong> \n\nData anda akan kami proses dalam waktu maksimal 24 jam setelah anda mengunggah bukti transfer.";
    span.innerHTML = this.state.info_swal;

    global.swal({title : "Success", /*"Kode Pembayaran anda adalah " + resData.data.year + resData.data.registration_type_id + resData.data.registration_number + "\n Nomor Rekening : 136-00-0149000-9 \na/n SMP Pangudi Luhur Domenico Savio SMG"*/
    icon: "success",
    
    content: span,})
    .then((value) => {
      window.location.href="/uploadbuktiseragam";
    });
  }

  fileUploadHandler = event => {

    event.preventDefault();


      global.swal({
        text: 'Apakah anda yakin akan disubmit?',
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: [
          'Cancel',
          {text: 'Submit', 
          closeModal: false,}
        ],
      })
      .then((confirm) => {
        let errorMessage =  { code : 403, message : 'cancel' };
        if (!confirm) throw errorMessage;
        
        const data = new FormData()
        // data.append('upload_file', this.state.selectedFile)
        data.append('student_id', localStorage.getItem('DATA_JALUR').split('-')[0])
        data.append('original_name', this.state.original_name)
        data.append('note', this.state.note)
        return fetch(global.variable.LINK_API + 'uniform/updateflow/3',
        {
          method: 'post',
          headers: {
              Accept: "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'),
          },
          body: data, 
        })
      })
      .then(results => {
        return results.json();
      })
      .then(resData => {
        if(resData.message==="Success"){
          console.log(resData);
          global.swal("Success", "<span style='color:red'>Silahkan menunggu proses verifikasi dan validasi dokumen anda dalam waktu maksimal 24 jam.  Jika lebih dari 24 jam belum ada verifikasi dari sekolah, silahkan menghubungi SMP DEMO via telepon.</span>", "success")

          var text = "<span style='color:red'>Silahkan menunggu proses verifikasi dan validasi dokumen anda dalam waktu maksimal 24 jam.  Jika lebih dari 24 jam belum ada verifikasi dari sekolah, silahkan menghubungi SMP DEMO via telepon.</span>"
          this.setState({info_swal : text});

          this.runswal();
          // .then((value) => {
          //   window.location.href="/uploadbuktiseragam";
          // });
        }
        
      })
      .catch(err => {
        console.log(err)
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
 
  };
  

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  download= param => e => {
    e.preventDefault();
    console.log(param)
    window.open(param, "_blank", 'location=yes,height=600,width=600,scrollbars=yes,status=yes')

    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  }

  formbukti(){
    return(
      <div>
      <Form.Group controlId="exampleForm.ControlInput1">
      <Form.Label>Catatan</Form.Label>
      <Form.Control 
        type="Text"
        name="note"
        maxLength="50"
        value={this.state.upload_uniform.note}
        onChange={this.onChange}  disabled/>
    </Form.Group>
       <Image alt="Bukti transfer" style={{width:400}} src={this.state.upload_uniform.link} thumbnail />
       </div>
    )
}
  
  formupload(){
    let guardian = this.state.upload_uniform;
    let showitem = [];
    //console.log(guardian);

    
      showitem.push(
        
        <Form.Group controlId="exampleForm.ControlSelect1">
          <div>
         
        <Form.Label>Bukti</Form.Label>
       
        <div className='rowHorizontal'>
        {/* <Image alt="Dokumen" style={{width:400}} src={this.state.upload_uniform.link} thumbnail /> */}
        
        
         <Link  target="_blank" onClick={this.download(this.state.upload_uniform.link)}>Lihat Bukti</Link>
        
        
         </div>
        </div>
      </Form.Group>
      )

    return showitem;
  }


  Buttonsub(){
    let guardian = this.state.upload_uniform;
    let showitem = [];
    //console.log(guardian);
    let show = false;
      if (this.state.upload_uniform ){
        show =true}
      
    showitem.push(
      <Form.Group controlId="exampleForm.ControlSelect1">
      {show?
      <div>
        <div className='rowHorizontal'>
      
        <Button type="submit" block style={{margin:5, height:50}} >Submit</Button>
      </div>
      </div>
      :''}
      
    </Form.Group>
    )

    return showitem;
  }



  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value});
  }


  // handleSubmit = event => {
  //   event.preventDefault();
  //   if(this.state.original_name ===''){
  //     global.swal("Error", "Harap pilih file gambar", "info")
  //   }else{

  //     global.swal({
  //       text: 'Apakah anda yakin akan disubmit?',
  //       closeOnEsc: false,
  //       closeOnClickOutside: false,
  //       buttons: [
  //         'Cancel',
  //         {text: 'Submit', 
  //         closeModal: false,}
  //       ],
  //     })
  //     .then((confirm) => {
  //       let errorMessage =  { code : 403, message : 'cancel' };
  //       if (!confirm) throw errorMessage;
  //       const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 9, note: this.state.note, encrypt_64 : this.state.encrypt_64 }
  //       //console.log(JSON.stringify(data));
  //       return fetch(global.variable.LINK_API + 'flow/update',
  //       {
  //         method: 'post',
  //         headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //             'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
  //         },
  //         body: JSON.stringify(data), 
  //       })
  //     })
  //     .then(results => {
  //       return results.json();
  //     })
  //     .then(resData => {
  //       if(resData.message==="Success"){
  //         console.log(resData);
  //         global.swal("Success", 'Silahkan menunggu proses verifikasi dan validasi dokumen anda dalam waktu maksimal 24 jam', "success")
  //         .then((value) => {
  //           window.location.href="/uploadbuktiUpp";
  //         });
  //       }
        
  //     })
  //     .catch(err => {
  //       console.log(err)
  //       if (err) {
  //         if(err.code!==403){
  //           global.swal("Error", "Koneksi ke server gagal.", "info")
  //         }
  //       } else {
  //         global.swal.stopLoading();
  //         global.swal.close();
  //       }
  //     });

      
  //   }
  // }


  handleSubmit = event => {
    event.preventDefault();
    if(this.state.original_name ===''){
      global.swal("Error", "Harap pilih file gambar", "info")
    }else{

      global.swal({
        text: 'Apakah anda yakin akan disubmit?',
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: [
          'Cancel',
          {text: 'Submit', 
          closeModal: false,}
        ],
      })
      .then((confirm) => {
        let errorMessage =  { code : 403, message : 'cancel' };
        if (!confirm) throw errorMessage;
        const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 9, note: this.state.note, encrypt_64 : this.state.encrypt_64 }
        //console.log(JSON.stringify(data));
        return fetch(global.variable.LINK_API + 'uniform/updateflow/3',
        {
          method: 'post',
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
          },
          body: JSON.stringify(data), 
        })
      })
      .then(results => {
        return results.json();
      })
      .then(resData => {
        if(resData.message==="Success"){
          console.log(resData);
          global.swal("Success", 'Silahkan menunggu proses verifikasi dan validasi dokumen anda dalam waktu maksimal 24 jam.  Jika lebih dari 24 jam belum ada verifikasi dari sekolah, silahkan menghubungi SMP DEMO via telepon.', "success")
          .then((value) => {
            window.location.href="/uploadbuktiseragam";
          });
        }
        
      })
      .catch(err => {
        console.log(err)
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

      
    }
  }

  handleBack = () => {
    window.location.href="/uploadbukti";
  }

  showUniform() {
   
    let showitem = [];
    let totalnominal = 0;
    for (let i = 0; i < this.state.uniform_data.length; i++) {
    totalnominal +=  this.state.uniform_data[i].qty * this.state.uniform_data[i].price
    
    showitem.push(
      
      <Form.Group controlId="exampleForm.ControlInput1">
      <Form.Group controlId="exampleForm.ControlInput1">
      <hr style={{Color:'black'}}></hr>
      <Form.Label>Seragam</Form.Label>
      <Form.Control 
        type="Text"
        name="jalur"
        value={this.state.uniform_data[i].uniform_name}
        disabled />
    </Form.Group>

    <Form.Group controlId="exampleForm.COntrolInput1">
      <Form.Label>Ukuran</Form.Label>
      <Form.Control 
        type="Text"
        name="jalur"
        value={this.state.uniform_data[i].size}
        disabled />
    </Form.Group>
    <Form.Group controlId="exampleForm.COntrolInput1">
      <Form.Label>Harga</Form.Label>
      <Form>
        <CurrencyFormat 
        value={this.state.uniform_data[i].price} 
        style={{ width : '100%'}} 
        thousandSeparator={true} 
        prefix={'Rp. '} disabled />
      </Form>
    </Form.Group>
    <Form.Group controlId="exampleForm.COntrolInput1">
      <Form.Label>Jumlah</Form.Label>
      <Form.Control 
        type="Text"
        name="jalur"
        value={this.state.uniform_data[i].qty}
        disabled />
    </Form.Group>
    <Form.Group controlId="exampleForm.COntrolInput1">
      <Form.Label>Total Harga</Form.Label>
      <Form>
        <CurrencyFormat 
        value={this.state.uniform_data[i].qty * this.state.uniform_data[i].price} 
        // value ={this.state.totalharga}
        style={{ width : '100%'}} 
        thousandSeparator={true} 
        prefix={'Rp. '} disabled />
      </Form>
    </Form.Group>
    <Form.Group controlId="exampleForm.COntrolInput1">
      {this.state.uniform_data[i].name?
      <div>
      <Form.Label>Nama</Form.Label>
      <Form.Control 
        type="Text"
        name="jalur"
        value={this.state.uniform_data[i].name}
        disabled />
        </div>
        :''}
    </Form.Group>
    </Form.Group>
        )
      }
      showitem.push(
        <Form.Group controlId="exampleForm.COntrolInput1">
          <Form.Label>Total Harga Keseluruhan</Form.Label>
          <Form>
            <CurrencyFormat 
            value ={totalnominal}
            style={{ width : '100%'}} 
            thousandSeparator={true} 
            prefix={'Rp. '} disabled />
          </Form>
        </Form.Group>
      )
      

      return showitem;
    
  }

  Total(){
    let totalnominal = 0;
    for (let i = 0; i < this.state.uniform_data.length; i++) {
        totalnominal +=  this.state.uniform_data[i].qty * this.state.uniform_data[i].price
    }
    console.log(totalnominal)
    return(totalnominal)
  }

  render() {
    let counter = 0;
    console.log(this.state.upload_uniform)

    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Upload Bukti Seragam</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.fileUploadHandler}>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Siswa</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="jalur"
                              value={localStorage.getItem('DATA_JALUR')}
                              disabled />
                          </Form.Group>
                          <Form.Group>
                          {this.state.uniform_data && this.state.uniform_data.length? 
                          // this.showUniform()
                <div className="rowHorizontal" >
              <div style={{margin:5, marginLeft:100, fontSize:11}}>  
              <table border="1">
                <thead>
                      <tr >
                        <th width="50" style={{textAlign: "center", fontSize:12}}>No. </th>
                        <th width="150" style={{textAlign: "center", fontSize:12}}>Nama Barang</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Harga Satuan</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Jumlah Barang</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Ukuran</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Jumlah Harga</th>
                      </tr>
                    </thead>
                    <tbody>
                        {this.state.is_showdata?
                       
                       
                        this.state.uniform.map((item, idx) => {
                        let showitem = [];
                        let price = 0;
                        let qty = 0;
                        let size ='-';
                        let total = 0;
                        
                        counter++;
                        //console.log(this.state.uniform_data.length)

                        for (let i = 0; i < this.state.uniform_data.length; i++) {
                           
                        // this.state.uniform_data.map((item2, idx2) => {
                            
                            //  console.log(this.state.uniform_data[i].price)
                            if(this.state.uniform_data[i].uniform_id == item.id){
                                price = this.state.uniform_data[i].price;
                                qty = this.state.uniform_data[i].qty;
                                size = this.state.uniform_data[i].size;
                                total = this.state.uniform_data[i].qty*this.state.uniform_data[i].price;

                               
                            }
                            else{
                                // console.log("tidak masuk if")

                            }
                           

                           
                        }
                        return (
                            <tr key={item.id}>
                                <td style={{textAlign: "center"}}>{counter}</td>
                                <td>{item.name}</td>
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={price}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                </td>
                                <td style={{textAlign: "center"}}>{qty}</td>
                                <td style={{textAlign: "center"}}>{size}</td>  
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={total}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                </td>
                            </tr>
                            
                        )
                      
                       
                        
                        
                        })
                    :''}
                      </tbody>
                     
                            <tr>
                                
                                <td colSpan="5" style={{textAlign: "center", fontSize:12}}>Total</td>
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={this.Total()}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                    </td>
                            </tr>
                      
                   
                    </table>
                  </div>
            </div>

                           : ''}
                          </Form.Group>
                          
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Catatan</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="note"
                              maxLength="50"
                              value={this.state.note}
                              onChange={this.onChange}  />
                          </Form.Group>


                          <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>Upload file gambar (*jpg dan *.png) atau pdf</Form.Label>
                            <input type="file" name="file" className="form-control" onChange={this.onChangeHandler}/>
                          </Form.Group>
                         
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label style={{ fontSize:18, color:'red'}}>*Klik tombol submit setelah upload</Form.Label>
                          </Form.Group>
                          <Form.Group>
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleUpload} block style={{margin:5, height:50}}>Upload</Button>
                          </div>
                          </Form.Group>
                          <Form.Group>
                          {this.state.upload_uniform && this.state.is_show? 
                          this.formupload()
                           : ''}
                          </Form.Group>
                          
                          <Form.Group>
                          {this.state.upload_uniform?
                          this.Buttonsub()
                          
                          :''}
                          
                          </Form.Group>
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;