import React, { Component } from "react";
import { Button, FormGroup, FormControl, FormLabel ,Image } from "react-bootstrap";
import "./Login.css";
import Cookies from 'js-cookie'
import { trackPromise } from 'react-promise-tracker';
//import "./css/styles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;


export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()

export const authenticate = async () => {
    try {
     
      //console.log(this.state);
      

      const tokens = '123';

      const expires = (tokens.expires_in || 60 * 60) * 1000
      const inOneHour = new Date(new Date().getTime() + expires)

      // you will have the exact same setters in your Login page/app too
      //Cookies.set('access_token', tokens.access_token, { expires: inOneHour })
      //Cookies.set('refresh_token', tokens.refresh_token)

      Cookies.set('access_token', '333', { expires: inOneHour })
      Cookies.set('refresh_token', '222')

      return true
    } catch (error) {
      return false
    }
}

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_id: "",
      password: "",
      height: '',
      passwordShown: false,
      setPasswordShown: false,
    };
    
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  validateForm() {
    return this.state.user_id.length > 0 && this.state.password.length > 0;
  }

  handleChangeuser = event => {

    this.setState({
      user_id: event.target.value
    });
  }

  handleChangepassword = event => {

    this.setState({
      password: event.target.value
    });
  }

  togglePasswordVisiblity = event =>{
    this.setState({
      passwordShown : !this.state.passwordShown
    });
  }

  register(){
    
    window.location.href="/register";
  }

  handleSubmit = event => {
    event.preventDefault();

    /*global.swal({
        title: 'Now loading',
        closeOnEsc: false,
        closeOnClickOutside: false,
        button: false,
        imageUrl: "assets/images/loader.gif",
        onOpen: () => {
          global.swal.showLoading();
        }
      }).then(
        
      )*/

      trackPromise(
      fetch(global.variable.LINK_API + 'auth/login',
      {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state), 
      })
      .then(response =>  response.json())
      .then(resData => {
        if(resData.message==="Success"){
          console.log(resData);
          const tokens = resData.access_token;
    
          const expires = (tokens.expires_in || 60 * 60) * 1000
          const inOneHour = new Date(new Date().getTime() + expires)
    
          // you will have the exact same setters in your Login page/app too
          //Cookies.set('access_token', tokens.access_token, { expires: inOneHour })
          //Cookies.set('refresh_token', tokens.refresh_token)
    
          Cookies.set('access_token', tokens, { expires: inOneHour })
          Cookies.set('refresh_token', 'refresh_token')

          
          localStorage.setItem('menu', JSON.stringify(resData.data));
          localStorage.setItem('userid', this.state.user_id);
          window.location.href="/home";
          //global.swal(Cookies.get('access_token'));
        }else{
          global.swal(resData.error);
        }
        
      })
      )
    

  }

  render() {

    return (
      <div className="Login" style={{backgroundImage: `url(${require(`./assets/images/background.png`)})`, backgroundPosition: 'center',  height:this.state.height,
      backgroundRepeat: 'no-repeat', backgroundSize: 'cover',}}>
      <div className="text-center" style={{background:'transparent', border: '0px', marginBottom:50}}>
        <Image alt="Bukti transfer" style={{width: 350, background:'transparent', }} src={require('./assets/images/logo_ppdb_online_domsav.png')} />
      </div>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="user_id" >
            <FormLabel style={{paddingLeft:12}}>User ID</FormLabel >
              <div className="pass-wrapper" style={{position: 'relative', display: 'flex', marginBottom: '14px'}}>
                <FormControl
                  autoFocus
                  type="text"
                  name="user_id"
                  value={this.state.user_id}
                  onChange={this.handleChangeuser}
                />
              </div>
          </FormGroup>
          <FormGroup controlId="password" >
            <FormLabel style={{paddingLeft:12}}>Password</FormLabel >
            {/* <FormControl
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            /> */}
             <div className="pass-wrapper" style={{position: 'relative', display: 'flex', marginBottom: '14px'}}>
              <FormControl
                placeholder="Password"
                name="password"
                type={this.state.passwordShown ? "text" : "password"}
                value={this.state.password}
                onChange={this.handleChangepassword}
                //ref={register({ required: "This is required." })}
              />
              <i style={{position: 'absolute', top: '25%', right: '10%', }} onClick={this.togglePasswordVisiblity}>{eye}</i>
            </div>
          </FormGroup>
         
          <Button
            block
            disabled={!this.validateForm()}
            type="submit"
          >
            Login
          </Button>

          <Button
            block
            onClick={this.register}
            type="button">
            Register
          </Button>
          <FormGroup controlId="password" >
            <FormLabel ><a href="/forgetpassword">Lupa Password?</a></FormLabel >
          </FormGroup>
        </form>
      </div>
    );
  }
}