import React, { Component, } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import 'moment/locale/id.js';
import { Link } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && 
      <div
          style={{
            width: "100%",
            height: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: '#fff',
          }}>
          <Loader type="ThreeDots" color="#3c8dbc" height="100" width="100" />
      </div>
  );  
}

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        data_siswa : '',
        document_id : '',
        note : '',
        encrypt_64 : '',
        original_name : '123',
        is_show : false,
        chkbox1 : false,
        chkbox2 : false,
        next : true,
        data_upload: [],
        data_image: [],
        note_document_upload : '',
        info_swal : '',
        file:null

    }
  }

  getFiles(e, files){
    // console.log(files)
    //console.log(e);
    //console.log(files[0].size);
    //console.log(files[0].size.split(' ')[0]);
    if(files[0].type==="image/jpeg" || files[0].type === "image/png"){
      if(files[0].size.split(' ')[0] > 2048){
        global.swal("Error", "Gambar tidak boleh lebih dari 2 MB", "info")
        this.setState({ encrypt_64: '', original_name: '',  })
      }
      else{
        //this.setState({ encrypt_64: files[0].base64, original_name: files[0].name,  })

        let item = this.state.data_image;
        let items =  Object.create({});
        items.student_id =  localStorage.getItem('DATA_JALUR').split('-')[0];
        items.document_id = e;
        items.original_name = files[0].name;
        items.encrypt_64 = files[0].base64;
        item.push(items);
        this.setState({data_image: item})
      }
    }else{
      global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png)", "info")
      this.setState({ encrypt_64: '', original_name: '',  })
    }
  }

  handleUploadAll= param => e => {
    this.refs.btnupload.setAttribute("disabled", "disabled");
    
    // console.log(this.state.data_image)
    let array = this.state.data_image;
    let counter = 0;
    
    array.forEach(function(entry, index) {
        // console.log(entry);
        // console.log(index);
        const data = { student_id :  entry.student_id, document_id : entry.document_id, original_name : entry.original_name, note: '', encrypt_64 : entry.encrypt_64 }
        const data2 = { data:JSON.stringify(data) }
        console.log(JSON.stringify(data2));
        trackPromise(
          fetch(global.variable.LINK_API + 'uploaddocument/store',
          {
            method: 'post',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
            },
            body: JSON.stringify(data2), 
          })
          .then(response =>  response.json())
          .then(resData => {
            if(resData.message==="Success"){
              counter = counter+1;
              // console.log("counter " + counter + " length " + array.length)
              if(counter===array.length ){
                global.swal("Success", resData.message, "success")
                .then((value) => {
                  window.location.href="/uploaddokumendetail";
                  this.setState({original_name : '', encrypt_64 : ''})
                });
              }
              
            }
            // console.log(resData);
            
          })
        )
    });

    
      

    
  }


  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0], encrypt_64 : '', original_name : ''});
    
    fetch(global.variable.LINK_API + 'uploaddocument/student/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        // console.log(resData);
        this.setState({ data_upload : resData.data});
      }
       
    })
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0]});
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        this.setState({is_show2 : true, is_show : true});
        this.setState({student_data : resData.data})
        // console.log(this.state);
        // console.log(resData.data)
      }
    })
  }

  createSelect() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmDocumentUpload;
    for (let i = 0; i < params.length; i++) {             
         items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);   
    }
    return items;
  }

  onChangeHandler= param => event => {
    var file = event.target.files[0];
    // if(param === 21 && file) {console.log(file)
    // console.log(param) === 21
    // console.log(item)
    if(file.size>2000000){
      global.swal("Error", "File tidak boleh lebih dari 2 MB", "info")
      this.setState({ selectedFile: '', original_name: '',  })
      
    }else if(param===1){
      if(file.type==="image/png"||file.type==="image/jpeg"){
        this.setState({selectedFile: file, original_name : file.name});
        
      }else{
        global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png) untuk pas foto", "info")
        this.setState({ selectedFile: '', original_name: '',  })
      }
    }
    // else if(param===21){
    //   if(file.type==="image/png"||file.type==="image/jpeg"||file.type==="application/pdf"){
    //     this.setState({selectedFile: file, original_name : file.name});
        
    //   }else{
    //     global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png) atau pdf", "info")
    //     this.setState({ selectedFile: '', original_name: '',  })
    //   }
    // }
    
    else if(param === 21 || file.type==="image/png"||file.type==="image/jpeg"||file.type==="application/pdf"){
        // console.log(file);
        this.setState({selectedFile: file, original_name : file.name});
    }else{
      global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png) atau pdf", "info")
      this.setState({ selectedFile: '', original_name: '',  })
    }
 
  }

  fileUploadHandler = param => e => {
    const data = new FormData()
    //console.log(this.state.selectedFile);
    data.append('upload_file', this.state.selectedFile)
    data.append('student_id', localStorage.getItem('DATA_JALUR').split('-')[0])
    data.append('document_id', param)
    data.append('original_name', this.state.original_name)
    /*for (var pair of data.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
    }*/

    /*axios.post(global.variable.LINK_API + 'uploaddocument/store', data, { 
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      'content-type': 'multipart/form-data',
    })
    .then(res => { // then print response status
      console.log(res.statusText)
    })*/


    fetch(global.variable.LINK_API + 'uploaddocument/store',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      body: data, 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        global.swal("Success", resData.message, "success")
        .then((value) => {
          window.location.href="/uploaddokumendetail";
          this.setState({original_name : '', selectedFile : ''})
        });
        
      }else{
        global.swal("Error", resData.error, "info")
      }
      // console.log(resData);
      
    })
 
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit = event => {
    /*event.preventDefault();
    this.setState({data_upload : ''});

    console.log(JSON.stringify(this.state));

    const data = { data:JSON.stringify(this.state) }

    fetch(global.variable.LINK_API + 'uploaddocument/store',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        global.swal("Success", resData.message, "success")
        .then((value) => {
          window.location.href="/uploaddokumendetail";
        });
        //window.location.href="/pendaftaran";
      }
       
    })*/
  }

  handleChangeChk = e => {
    this.setState({[e.target.name]: e.target.checked});
  }


  handleUpload = param => e => {
    if(this.state.original_name ===''){
      global.swal("Error", "Harap pilih file gambar", "info")
    }else{
      const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], document_id : param, original_name : this.state.original_name, note: this.state.note, encrypt_64 : this.state.encrypt_64 }
      const data2 = { data:JSON.stringify(data) }
      // console.log(JSON.stringify(data2));

      fetch(global.variable.LINK_API + 'uploaddocument/store',
      {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
        },
        body: JSON.stringify(data2), 
      })
      .then(response =>  response.json())
      .then(resData => {
        if(resData.message==="Success"){
          global.swal("Success", resData.message, "success")
          .then((value) => {
            window.location.href="/uploaddokumendetail";
            this.setState({original_name : '', encrypt_64 : ''})
          });
          
        }
        // console.log(resData);
        
      })
      

    }
  }

  handleBack = () => {
    window.location.href="/uploaddokumen";
  }

  handleSave = event => {
    let document = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmDocumentUpload;
    let dtup = this.state.data_upload;
    let enc=0;
    let req=0;
    // console.log(document)
    document.map((item) =>{
      if(item.is_required||(item.id === 3 &&this.state.parent_status_id===1) ||(item.id === 21 &&this.state.both_parent_work===1)){
        // console.log(item.is_required) 
        req++;
        
        for (let i = 0; i < dtup.length; i++) { 
          // console.log(dtup.length)
          // console.log(item.id)
          // console.log(item.is_required ||item.id)
          if(dtup[i].document_id === item.id){
            // if(dtup[i].document_id === item.id||(dtup[i].document_id === 3 &&this.state.parent_status_id===1) ||(dtup[i].document_id === 21 &&this.state.both_parent_work===1)){
            // console.log(item.id)
            enc= enc+1;
          }
          
        }
        return false;
        // for (let i = 0; i < dtup.length; i++) { 
        //   console.log(dtup.length)
        //   if(dtup[i].document_id === 21){
        //     enc= enc+1;
        //   } else if(dtup[i].document_id === item.id){
        //     enc= enc+1;
        //   }
        // }

      }
      return enc;
    })

    // console.log('req ' +req)
    // console.log('enc ' +enc)

    if(enc===req){
      if(this.state.chkbox1&&this.state.chkbox2){
        let flow = 2;
        this.state.is_send_notif_payment_register?flow=3:flow=2;
        //is_va && flow 7 jika va, !is_va dan flow 2 jika upload bukti bayar
        const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : flow, is_va : 0, note_document_upload : this.state.note_document_upload }
        // console.log(data)
  
        fetch(global.variable.LINK_API + 'flow/update',
        {
          method: 'post',
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
          },
          body: JSON.stringify(data), 
        })
        .then(response =>  response.json())
        .then(resData => {
          if(resData.message==="Success"){
            
            //Jika tidak menggunakan VA dan data awal
            var text = "Terima kasih anda telah mendaftar ke Sekolah Demo. </br></br>Nomor Pendaftaran anda adalah <strong><span style='color:red'>" + resData.data.year + '-' + resData.data.registration_type_id  + '-' +  resData.data.registration_number +"</span></strong></br></br><strong>Silahkan melakukan transfer biaya pendaftaran sebesar <span style='color:red'>Rp.100.000</span> ke rekening <span style='color:red'>Bank Mandiri </br>no 136-00-0149999-9 atas nama Sekolah Demo</span> dengan mencantumkan nomor pendaftaran anda pada kolom catatan.</strong> </br></br>Data anda akan kami proses dalam waktu maksimal 24 jam setelah anda mengunggah bukti transfer.";

            //Jika tidak menggunakan VA dan data yang dikembalikan
            if(this.state.is_send_notif_payment_register){
              var text = "Silahkan menunggu proses validasi ulang dokumen anda dalam waktu maksimal 24 jam";
            }

            //Pakai VA
            //var text = "Terima kasih anda telah mendaftar ke SMP PL Domenico Savio Semarang. </br></br>Nomor Pendaftaran anda adalah <strong><span style='color:red'>" + resData.data.year + '-' + resData.data.registration_type_id  + '-' +  resData.data.registration_number +"</span></strong></br></br><strong>Silahkan melakukan pembayaran biaya pendaftaran sebesar <span style='color:red'>Rp.100.000</span> ke rekening <span style='color:red'>Virtual Account Bank Mandiri </br>no "+ resData.data.va[0].va_number +" </span></strong> </br></br>Mohon agar dicatat nomor Virtual Account Pendaftaran Anda.";

            this.setState({info_swal : text});
            this.runswal();
          }
          
        })
      }else{
        global.swal("Error", "Harap mencentang semua persyaratan", "info")
      }
    }else{
      global.swal("Error", "Harap mengupload gambar yang wajib (* tanda bintang)", "info")
    }


    /**/
  }

  runswal(){
    var span = document.createElement("span");
    //span.innerHTML = "Terima kasih anda telah mendaftar ke SMP PL Domenico Savio Semarang. \n\nNomor Pendaftaran anda adalah " + resData.data.year + '-' + resData.data.registration_type_id  + '-' +  resData.data.registration_number +"\n\n<strong>Silahkan melakukan transfer biaya pendaftaran sebesar Rp.100.000 ke rekening Bank Mandiri no 136-00-0149000-9 atas nama SMP PANGUDI LUHUR DOMENICO SAVIO SMG dengan mencantumkan nomor pendaftaran anda pada kolom catatan.</strong> \n\nData anda akan kami proses dalam waktu maksimal 24 jam setelah anda mengunggah bukti transfer.";
    span.innerHTML = this.state.info_swal;

    global.swal({title : "Success", /*"Kode Pembayaran anda adalah " + resData.data.year + resData.data.registration_type_id + resData.data.registration_number + "\n Nomor Rekening : 136-00-0149000-9 \na/n SMP Pangudi Luhur Domenico Savio SMG"*/
    icon: "success",
    
    content: span,})
    .then((value) => {
      window.location.href="/uploaddokumen";
    });
  }

  form1(){
    let showitem = [];
    let arrayitem = this.state.data_upload;
    // console.log(arrayitem);

    for (let i = 0; i < arrayitem.length; i++) { 
      showitem.push(
        <div key={arrayitem[i].name}>
          <hr/>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Dokumen </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>{arrayitem[i].document.name } </Form.Label>
            
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Catatan </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>{arrayitem[i].note} </Form.Label>
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5, height: 30}}>File </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}><Button onClick={this.handlefile.bind(this, arrayitem[i].encrypt_64)} block style={{height:30, width: 100, fontSize: 10,}}> Download </Button></Form.Label>
          </div>
        </div>
      );
    }

    return showitem;
  }

  download= param => e => {
    e.preventDefault();
    // console.log(param)
    window.open(param, "_blank")
  }

  handlefile(e){
    let base64String = e; 
    let base64Image = base64String.split(';base64,').pop();

    // console.log( base64Image);

    const element = document.createElement("a");
    const file = new Blob(base64Image, {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  render() {
    let document = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmDocumentUpload;
    //console.log(document);
    //console.log(this.state.data_upload)
    
    let dtup = this.state.data_upload;
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Upload Dokumen</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.handleSubmit}>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Siswa</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="jalur"
                              value={localStorage.getItem('DATA_JALUR')}
                              disabled />
                          </Form.Group>
                          <Form.Label>Semua file berupa image (*.jpg dan *.png) atau pdf dan tidak lebih dari 2 MB</Form.Label>
                          <hr/>
                          {
                            document.map((item) =>{
                              let download_document;
                              if(item.download_document){
                                download_document = item.download_document
                              }
                              let enc;
                              for (let i = 0; i < dtup.length; i++) { 
                                if(dtup[i].document_id === item.id){
                                  enc = dtup[i].link;
                                }
                              }
                            return(
                              <div key={item.id}>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                  {/* <Form.Label>{item.name} { item.is_required ? '*':''}</Form.Label> */}
                                  <Form.Label>{item.name} { item.is_required ||(item.id === 3 &&this.state.parent_status_id===1) ||(item.id === 21 &&this.state.both_parent_work===1) ? '*':''}</Form.Label>
                                  {/* {console.log(item.id)} */}
                                  <div style={{marginLeft : 10}}>
                                    {download_document? 
                                    <Link  target="_blank" onClick={this.download(download_document)}>Download Template</Link>
                                    : null }
                                  </div>
                                </Form.Group>
                                  
                                <div className='rowHorizontal'>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                  {/*  */}
                                  <input type="file" name="file" className="form-control" onChange={this.onChangeHandler(item.id)}/>
                                  
                                    <Button onClick={this.fileUploadHandler(item.id)} block style={{marginTop:5, height:30}}>Upload</Button>
                                    {/* <Button onClick={this.fileUploadHandler(item.is_required ||(item.id === 21 &&this.state.both_parent_work===1) )} block style={{marginTop:5, height:30}}>Upload a</Button> */}
                                    {/* {console.log(item.id === 21)} */}
                                    {/* {console.log(item.id === 21)} */}
                                </Form.Group>
                                <div style={{marginLeft : 10}}>
                                  {/*item.is_required? 
                                      enc? 1 : 0
                                      : 1
                                  */}
                                  {enc? 
                                  <div>
                                    <a href={enc} target="_blank" rel="noopener noreferrer"><strong>Lihat Dokumen</strong></a>
                                    <hr style={{margin:10}}></hr>
                                    <span style={{color:'red'}}><strong>Dokumen sudah diupload</strong></span>
                                  </div>
                                  : null }
                                </div>
                                </div>
                                <div className='rowHorizontal'>
                                </div>
                                <hr/>
                              </div>
                            ) 
                            })
                          }
                          
                          {/*this.state.is_show?
                            <div>
                            <hr/>
                            <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Data Upload</Form.Label>
                            </div>
                            : ''}
                          {this.state.is_show ? 
                            this.form1()
                          : ''*/}
                          
                          
                          <LoadingIndicator/>
                          {/*<Form.Group>
                          <div className='rowHorizontal'>
                            <Button ref="btnupload" onClick={this.handleUploadAll()} block style={{marginTop:5, height:30}}>Upload</Button>
                          </div>
                          </Form.Group>*/}

                          <hr/>
                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Catatan Dokumen</Form.Label>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Catatan</Form.Label>
                            <Form.Control 
                              as="textarea" rows="5"
                              name="note_document_upload"
                              maxLength="100"
                              value={this.state.note_document_upload}
                              onChange={this.onChange} 
                              />
                          </Form.Group> 

                          <Form.Group>
                            <div className='rowHorizontal'>
                              <input style={{margin:10}} type="checkbox" name="chkbox1" defaultChecked={this.state.chkbox1} onChange={this.handleChangeChk}/>
                              <label style={{margin:5}}>Saya mengisi semua data diatas dalam keadaan sehat, sadar dan informasi yang saya tuliskan dalam data tersebut adalah jujur dan benar</label>
                            </div>
                            <div className='rowHorizontal'>
                              <input style={{margin:10}} type="checkbox" name="chkbox2" defaultChecked={this.state.chkbox2} onChange={this.handleChangeChk}/>
                              <label style={{margin:5}}>Apabila di kemudian hari terbukti ditemukan kebohongan, pemalsuan atau kesalahan yang disengaja pada penulisan informasi maupun dokumen pelengkap, maka kami bersedia menanggung resiko berupa :</label>
                            </div>
                            <div className='rowHorizontal'>
                              <label style={{margin:5, marginLeft:40}}>a.</label>
                              <label style={{margin:5}}>Dibatalkan dari pencalonannya sebagai  calon Peserta Didik Baru, apabila belum memasuki tahun pelajaran 2021/2022</label>
                            </div>
                            <div className='rowHorizontal'>
                              <label style={{margin:5, marginLeft:40}}>b.</label>
                              <label style={{margin:5}}>Dikeluarkan dari Sekolah Demo,apabila sudah menjalani tahun pelajaran 2021/2022</label>
                            </div>
                            <div className='rowHorizontal'>
                              <label style={{margin:5, marginLeft:40}}>c.</label>
                              <label style={{margin:5}}>Mendapatkan penyesuaian kembali terhadap besaran uang yang harus ditanggung oleh peserta didik</label>
                            </div>
                          </Form.Group>
                           
                          <Form.Group>
                          {this.state.is_show?
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
                            <Button onClick={this.handleSave} block style={{margin:5, height:50}}>Submit</Button>
                            {/* <Button onClick={this.handleSave( item.is_required ||(item.id === 21 &&this.state.both_parent_work===1))} block style={{margin:5, height:50}}>Submit</Button> */}
                            
                          </div>
                          : ''}
                          </Form.Group>
                        </Form>

                      </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;