import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';

class Home extends Component {

  constructor(props){
    super(props);

    this.state={
        data_student : '',
        is_show: false,
    }
  }

  componentDidMount(){
    fetch(global.variable.LINK_API + 'student',
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState({data_student : resData.data});
        this.setState({is_show : true});
        //console.log(this.state);
      }
    })
  }

  handleReject= param => e => {
    console.log(param)

    const data = { student_id :  param, flow_id : 99, note_confirmation: ''}

    fetch(global.variable.LINK_API + 'flow/update',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        
        global.swal("Success", 'Berhasil Tolak', "success")
        .then((value) => {
          window.location.href="/home";
        });
      }
       
    })
  }

  handleApprove= param => e => {
    console.log(param)

    const data = { student_id :  param, }

    fetch(global.variable.LINK_API + 'student/confirmation',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        
        global.swal("Success", 'Berhasil Disetujui', "success")
        .then((value) => {
          window.location.href="/home";
        });
      }
       
    })
  }

  form(){
    
    let array = this.state.data_student;
    let showitem = [];
    //console.log(array);

    for (let i = 0; i < array.length; i++) { 
      showitem.push(
        <div key={array[i].id}>
          <hr/>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Nama </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>: {array[i].fullname} </Form.Label>
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>No Pendaftaran </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>: { array[i].year + '-' + array[i].registration_type_id  + '-' +  array[i].registration_number } </Form.Label>
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Status </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>: { array[i].flow.name}</Form.Label>
          </div>
          {array[i].is_confirmation?
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Alasan Pindah</Form.Label>
            <Form.Label className="col-md-6" style={{ margin:5}}>: {array[i].note_change_registration_type}</Form.Label>
          </div>
          : ''}
          {array[i].is_confirmation?
          <div className='rowHorizontal'>
            <Form.Label className="col-md-8" style={{ margin:5}}>{array[i].note_confirmation}</Form.Label>
            <Button className="col-md-2" onClick={this.handleApprove(array[i].id)}  style={{margin:2, height:30}}>Setuju</Button>
            <Button className="col-md-2" onClick={this.handleReject(array[i].id)}  style={{margin:2, height:30}}>Tolak</Button>
          </div>
          : ''}
        </div>
      );
    }

    return showitem;
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                    <div className="box">
                        <div className="divContainter">
                          <Breadcrumb>
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          </Breadcrumb>
                                
                          <div className="box-body">
                            <div className="row">
                                {this.form()}
                            </div>
                          </div>
                                
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        </div>
    );
  }
}

export default Home;