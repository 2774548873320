import React, { Component } from 'react';
import { Breadcrumb, Table, Button} from 'react-bootstrap';
import ReactExport from "react-export-excel";
import CurrencyFormat from 'react-currency-format';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Home extends Component {

  constructor(props){
    super(props);

    this.state={
        data_student : '',
        is_show: false,
        data: '',
        totalnominalupp:'',
    }
  }
  handleClick = param => e => {
    e.preventDefault();
    
    localStorage.setItem('student_id', param)
    window.open( "/detailstudent", '_blank');
  }
  componentDidMount(){

    
    console.log((localStorage.getItem('data_report')))
    let data_report = JSON.parse(localStorage.getItem('data_report'));
      let nominaltotal = 0;
    
    let data = []
    data_report.map((item) =>{
      let items =  Object.create({});
      let nominal2 = 0;
      let totalnominal = 0;
    
      items.fullname = item.fullname;
      items.pendaftaran =item.year + '-' + item.registration_type_id  + '-' +  item.registration_number;
      items.hp = item.hp;
    if (item.interview_by){
    let potongan2 = item.data_bayar[0].nominal * item.data_bayar[0].potongan / 100
    nominal2 = item.data_bayar[0].nominal - potongan2;
                   
    items.upp = 'Rp' + nominal2.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    else{items.upp=''}

    if(item.upload_upp && item.upload_upp.length){
     
      for(let i=0; i<item.upload_upp.length; i++){
        
        totalnominal += item.upload_upp[i].nominal;
       
      }
     
      items.bayar = 'Rp' + totalnominal.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      items.presen = (totalnominal / nominal2 * 100).toFixed(2) + "%";
    }
    else{items.bayar = '';
         items.presen = ''}

    
    if(item.upload_upp && item.upload_upp.length) {
      nominaltotal += totalnominal
    }
    else(items.totalnominalupp = '')

    

    items.note = item.note_verification?
                 item.note_verification:'';

   
      
      data.push(items);
    })

    let items = Object.create({});
      items.fullname = 'TOTAL NOMINAL'
      items.bayar = 'Rp' + nominaltotal.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      data.push(items);
    console.log(data);


    this.setState({report:data}, () => {this.setState({is_show: true, totalnominalupp: nominaltotal})


    })

  }

  

  

  render() {
    let data_report = JSON.parse(localStorage.getItem('data_report'));
    let counter = 0;
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                    <div className="box">
                        <div className="divContainter">
                          <Breadcrumb>
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Report Bukti UPP</Breadcrumb.Item>
                          </Breadcrumb>

                          {this.state.is_show?
                          <ExcelFile element={<Button block style={{margin:5, height:50}}>Download Data</Button>}>
                            <ExcelSheet data={this.state.report} name="Report Wawancara">
                                <ExcelColumn label="Nama Lengkap" value="fullname"/>
                                <ExcelColumn label="Nomor Pendaftaran" value="pendaftaran"/>
                                <ExcelColumn label="Nomor Handphone" value="hp"/>
                                <ExcelColumn label="UPP" value="upp"/>
                                <ExcelColumn label="Total Nominal Sudah Dibayarkan" value="bayar"/>
                                <ExcelColumn label="Persentase yang sudah dibayar" value="presen"/>
                                <ExcelColumn label="Catatan Tentang Pendaftar" value="note"/>
                               

                            </ExcelSheet>
                          </ExcelFile>
                          :''}
                             
                          <div className="box-body">
                            <div className="row">
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Nama Lengkap (Nama dapat diklik)</th> 
                                  <th>Nomor Pendaftaran</th>
                                  <th>Nomor Handphone</th>
                                  <th>UPP</th>
                                  <th>Total Nominal Sudah dibayarkan</th>
                                  <th>Persentase yang sudah dibayar (%) </th>
                                </tr>
                              </thead>
                              <tbody>
                              {data_report.map((item) =>{
                                let nominal2 =0;
                                let potongan2 = 0;
                                  if (item.interview_by){
                                    let potongan2 = item.data_bayar[0].nominal * item.data_bayar[0].potongan / 100
                                    nominal2 = item.data_bayar[0].nominal - potongan2}
                                                   
                                  else{nominal2 =0};
                                
                                let totalnominal = 0
                                let presen = 0
                                if(item.upload_upp && item.upload_upp.length){
                                  for(let i=0; i<item.upload_upp.length; i++){
                                    
                                    totalnominal += item.upload_upp[i].nominal;
                                  }
                                    presen = (totalnominal / nominal2 * 100).toFixed(2) + "%";
                                 
                                }
                                else{totalnominal= '';
                                     presen = ''}
                                  console.log(item)
                                  counter++;
                              return(
                                <tr>
                                  <td>{counter}</td>
                                  <td style={{color : 'blue'}} onClick={this.handleClick(item.id)}> {item.fullname}</td>
                                  <td>{item.year + '-' + item.registration_type_id  + '-' +  item.registration_number }</td>
                                  <td>{item.hp}</td>
                                  <td>{item.interview_by? 
                                       <CurrencyFormat 
                                        input="number"
                                        prefix={'Rp. '}
                                        readOnly
                                        value={nominal2}
                                        maxLength="25"  thousandSeparator={true}
                                        style={{ width : '100%'}} displayType={'text'} />:''}</td>
                                  <td>{item.interview_by? 
                                       <CurrencyFormat 
                                        input="number"
                                        prefix={'Rp. '}
                                        readOnly
                                        value={totalnominal}
                                        maxLength="25"  thousandSeparator={true}
                                        style={{ width : '100%'}} displayType={'text'} />:''}</td>
                                   <td>{item.interview_by? 
                                       <CurrencyFormat 
                                        input="number"
                                        readOnly
                                        value={presen}
                                        maxLength="25"  thousandSeparator={true}
                                        style={{ width : '100%' }} displayType={'text'} />:''}</td>
                                </tr>
                              )
                             })}
                             </tbody>

                                <tr>
                                  <td colSpan = '5'>TOTAL NOMINAL</td>
                                  <td>
                                    <CurrencyFormat
                                      input="number"
                                      prefix={'Rp. '}
                                      readOnly
                                      value={this.state.totalnominalupp}
                                      maxLength="25" thousandSeparator={true}
                                      style={{ width: '100%' }} displayType={'text'} />&nbsp;</td>
                                </tr>
                            </Table>
                            </div>
                          </div>
                                
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        </div>
    );
  }
}

export default Home;