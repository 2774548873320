import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import 'moment/locale/id.js';
import {  DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import CurrencyFormat from 'react-currency-format';
import remSep from "string-remove-thousand-separators";

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        registration_type : '',
        fullname : '',
        nickname : '',
        gender_id : '',
        nik : '',
        no_kk : '',
        nis : '',
        nisn : '',
        pob : '',
        dob : '2000-01-01',
        birth_certificate : '',
    }
  }

  componentDidMount() {
    if(localStorage.getItem('reg1')){
      this.setState(JSON.parse(localStorage.getItem('reg1')));
    }
    this.setState({registration_type: localStorage.getItem('DATA_JALUR').split('-')[0]});

    /*fetch(global.variable.LINK_WS + 'auth/user3',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      localStorage.setItem('DATA_PARAMETER', JSON.stringify(resData));
       
    })*/
  }

  createSelectGender() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmGender;
    for (let i = 0; i < params.length; i++) {             
         items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);   
    }
    return items;
  }

  onChange = e => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a })

  }

  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  onChangeDate = (jsDate, dateString) => {
    this.setState({dob: dateString});
  }

  validateForm() {
    return this.state.fullname.length > 0 
    && this.state.nickname.length > 0
    && this.state.nickname.length > 0
    && this.state.gender_id.length > 0
    && this.state.nik.length > 0
    && this.state.no_kk.length > 0
    && this.state.nis.length > 0
    && this.state.nisn.length > 0
    && this.state.pob.length > 0
    && this.state.dob.length > 0
    && this.state.birth_certificate.length > 0;
  }

  handleSubmit = event => {
    event.preventDefault();
    console.log("state 1 " + JSON.stringify(this.state));

    if(this.state.fullname===""){
      global.swal("Info", "Harap isi nama lengkap", "info");
    }else if(this.state.nickname===""){
      global.swal("Info", "Harap isi nama panggilan", "info");
    }else if(this.state.gender_id===""){
      global.swal("Info", "Harap pilih jenis kelamin", "info");
    }else{
      localStorage.setItem('reg1', JSON.stringify(this.state));
      window.location.href="/pendaftaran2";
    }
  }

  handleBack = () => {
    window.location.href="/pendaftaran";
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Pendaftaran Step 1</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.handleSubmit}>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Jalur</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="jalur"
                              value={localStorage.getItem('DATA_JALUR')}
                              disabled />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Lengkap (Sesuai Akta Kelahiran)*</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="fullname"
                              maxLength="100"
                              value={this.state.fullname}
                              onChange={this.onChange}
                              required />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Panggilan *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="nickname"
                              maxLength="100"
                              value={this.state.nickname}
                              onChange={this.onChange}
                              required  />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Jenis Kelamin *</Form.Label>
                            <Form.Control as="select" name="gender_id" onChange={this.onDropdownSelected.bind(this)} value={this.state.gender_id} required>
                              <option value="">-Pilih Jenis Kelamin-</option>
                              {this.createSelectGender()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>NIK (Nomor Induk Kependudukan) => cek di Kartu Keluarga *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="nik" 
                              onChange={this.onChange} 
                              value={this.state.nik}
                              maxLength="25"
                              style={{ width : '100%'}} required />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>NO KK (Nomor Induk Keluarga) => cek di Kartu Keluarga *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="no_kk" 
                              onChange={this.onChange} 
                              value={this.state.no_kk}
                              maxLength="25"
                              style={{ width : '100%'}} required />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>NIS (Nomor Induk Siswa) => cek di Rapor SD *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="nis"
                              maxLength="50"
                              value={this.state.nis}
                              onChange={this.onChange} 
                              required />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>NISN (Nomor Induk Siswa Nasional) => cek di Rapor SD / website Kemdikbud *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="nisn"
                              maxLength="50"
                              value={this.state.nisn}
                              onChange={this.onChange}
                              required  />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Tempat Lahir *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="pob"
                              maxLength="100"
                              value={this.state.pob}
                              onChange={this.onChange}
                              required  />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Tanggal Lahir *</Form.Label>
                            <DatePickerInput
                              displayFormat='DD-MM-YYYY'
                              returnFormat='YYYY-MM-DD'
                              className='my-react-component'
                              defaultValue={this.state.dob}
                              value={this.state.dob}
                              onChange={this.onChangeDate}
                              readOnly={true}
                            />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>No Akta Kelahiran *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="birth_certificate"
                              maxLength="50"
                              value={this.state.birth_certificate}
                              onChange={this.onChange}
                              required  />
                          </Form.Group>
                          <Form.Group>
                          <div className='rowHorizontal'>
                            <Button disabled={!this.validateForm()} type="submit" block style={{margin:5, height:50}} >Next</Button>
                          </div>
                          </Form.Group>
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;