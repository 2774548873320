import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import 'moment/locale/id.js';
import 'rc-datepicker/lib/style.css';
import CurrencyFormat from 'react-currency-format';
import remSep from "string-remove-thousand-separators";

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        religion_id : '',
        citizen_id : '',
        address : '',
        rt : '',
        rw : '',
        sub_district : '',
        district : '',
        sub_province : '',
        province : '',
        zipcode : '',
        longitude : '',
        latitude : '',
    }
  }

  validateForm() {
    return this.state.religion_id.length > 0 
    && this.state.citizen_id.length > 0
    && this.state.address.length > 0
    && this.state.rt.length > 0
    && this.state.rw.length > 0
    && this.state.sub_district.length > 0
    && this.state.district.length > 0
    && this.state.sub_province.length > 0
    && this.state.province.length > 0
    && this.state.zipcode.length > 0
    && this.state.longitude.length > 0
    && this.state.latitude.length > 0;
  }

  componentDidMount() {
    if(localStorage.getItem('reg2')){
      this.setState(JSON.parse(localStorage.getItem('reg2')));
    }
  }

  createSelectReligion() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmReligion;
    for (let i = 0; i < params.length; i++) {             
         items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);   
    }
    return items;
  }

  createSelectCitizen() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmCitizen;
    for (let i = 0; i < params.length; i++) {             
         items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);   
    }
    return items;
  }

  onChange = e => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a })
  }

  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit = event => {
    event.preventDefault();  
    if(this.state.religion_id===""){
      global.swal("Info", "Harap pilih agama", "info");  
    }else if(this.state.citizen_id===""){
      global.swal("Info", "Harap pilih kewarganegaraan", "info");
    }else{
      console.log("state 2 " + JSON.stringify(this.state));

      localStorage.setItem('reg2', JSON.stringify(this.state));
      window.location.href="/pendaftaran3";
    }
    
  }

  handleBack = () => {
    window.location.href="/pendaftaran1";
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Pendaftaran Step 2</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.handleSubmit}>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Agama *</Form.Label>
                            <Form.Control as="select" name="religion_id" onChange={this.onDropdownSelected.bind(this)} value={this.state.religion_id} required>
                              <option value="">-Pilih Agama-</option>
                              {this.createSelectReligion()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Kewarganegaraan *</Form.Label>
                            <Form.Control as="select" name="citizen_id" onChange={this.onDropdownSelected.bind(this)} value={this.state.citizen_id} required>
                              <option value="">-Pilih Kewarganegaraan-</option>
                              {this.createSelectCitizen()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Alamat *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="address"
                              maxLength="200"
                              value={this.state.address}
                              onChange={this.onChange} required/>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>RT *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="rt" 
                              onChange={this.onChange} 
                              value={this.state.rt}
                              maxLength="5"
                              style={{ width : '100%'}} 
                              required />
                            </Form> 
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>RW *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="rw" 
                              onChange={this.onChange} 
                              value={this.state.rw}
                              maxLength="5"
                              style={{ width : '100%'}} 
                              required />
                            </Form> 
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Kelurahan *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="sub_district"
                              maxLength="100"
                              value={this.state.sub_district}
                              onChange={this.onChange} required />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Kecamatan *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="district"
                              maxLength="100"
                              value={this.state.district}
                              onChange={this.onChange} required />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Kabupaten/Kota *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="sub_province"
                              maxLength="100"
                              value={this.state.sub_province}
                              onChange={this.onChange} required />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Provinsi *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="province"
                              maxLength="100"
                              value={this.state.province}
                              onChange={this.onChange} required />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Kodepos *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="zipcode" 
                              onChange={this.onChange} 
                              value={this.state.zipcode}
                              maxLength="5"
                              style={{ width : '100%'}} 
                              required />
                            </Form> 
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Garis Bujur *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="longitude"
                              maxLength="25"
                              value={this.state.longitude}
                              onChange={this.onChange}  
                              required/>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Garis Lintang *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="latitude"
                              maxLength="25"
                              value={this.state.latitude}
                              onChange={this.onChange}  
                              required/>
                          </Form.Group>
                          <Form.Group>
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
                            <Button type="submit" disabled={!this.validateForm()} block style={{margin:5, height:50}} >Next</Button>
                          </div>
                          </Form.Group>
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;