import React, {Component} from 'react';
import Logo from '../assets/images/logo-YPL.png';


export default class SideBar extends Component {

    componentDidMount(){
        //console.log(JSON.parse(localStorage.getItem('menu')));
    }

    render(){
        let menu = JSON.parse(localStorage.getItem('menu'));
        return (
            <aside className="main-sidebar">
                <section className="sidebar">
                    <div className="user-panel">
                        <div className="pull-left image">
                            <img src={Logo} className="img-circle" alt="User" />
                        </div>
                        <div className="pull-left info">
                            <p>{localStorage.getItem('userid')}</p>
                            <span><i className="fa fa-circle text-success"></i> Online</span>
                        </div>
                    </div>
                    <ul className="sidebar-menu" data-widget="tree">
                        <li className="header">Menu</li>
                        <li>
                            <a href="/home">
                                <i className="fa fa-home"></i> 
                                <span>Home</span>
                            </a>
                        </li>
                        {menu.map((item)=>{ 
                            return(
                                <li key={item.menu_id}>
                                    <a href={item.menu[0].redirect_to}>
                                        <i className={item.menu[0].icon}></i> 
                                        <span>{item.menu[0].name}</span>
                                    </a>
                                </li>
                            )
                        } )}

                        {/*<li>
                            <a href="/uploaddokumen">
                                <i className="fa fa-upload"></i> 
                                <span>Upload Dokumen</span>
                            </a>
                        </li>
                        <li>
                            <a href="/uploadbukti">
                                <i className="fa fa-check "></i> 
                                <span>Upload Bukti Transfer</span>
                            </a>
                        </li>
                        <li>
                            <span><hr/></span>
                        </li>
                        <li>
                            <a href="/verifikasi">
                                <i className="fa fa-users"></i> 
                                <span>Verifikasi</span>
                            </a>
                        </li>*/}
                    </ul>
                </section>
            </aside> 
        )
    }
}
