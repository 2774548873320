import React, { Component } from 'react';
import {Breadcrumb, Button, Image} from 'react-bootstrap';
import 'moment/locale/id.js';
import ReactToPrint from "react-to-print";
const moment = require('moment');

class ComponentToPrint extends React.Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        is_show : false,
        image_64 : '',
    }
  }

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0]});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        this.setState({is_show : true});
        console.log(this.state);
        
        let id = '';
        this.state.upload_documents[0].document_id===1? id = this.state.upload_documents[0].id : id = '';
        
        if(id!==''){
          fetch(global.variable.LINK_API + 'document/show/'+id,
          {
            method: 'get',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer '+ global.cookies.get('access_token'),
            },
            //body: JSON.stringify(this.state), 
          })
          .then(response =>  response.json())
          .then(resData => {
              console.log(JSON.stringify(resData));
            if(resData.message==="Success"){
              this.setState({image_64 : resData.data[0].link});
            }else{
              let msgerr = "";
              if(resData.errors){
                let count = Object.keys(resData.errors).length;
                for(let i=0; i<count; i++){
                  msgerr = msgerr + resData.errors[Object.keys(resData.errors)[i]] + "\n";
                }
              }
              global.swal(msgerr)
            }
            
          })
        }
      }
    })

    
  }

  render() {
    return (

      this.state.is_show?
        <div id="divPrint" style={{width:810, border: '0px solid black'}}>
            <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center' ,textAlign: 'center',}}>
                <img src={require("../assets/images/kop_surat.png")} alt="kop" style={{width:800}}/>
            </div>
            <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:20, marginTop:20, textAlign: 'center', }}>
              <label style={{textDecorationLine: 'underline'}}>SURAT KEPUTUSAN DAN UNDANGAN AGENDA FINANSIAL</label>
            </div>

            <div className="rowHorizontal">
              <div style={{width:160, height:160, border: '1px solid black', margin:20, marginLeft:50}}>
                <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:15, marginTop:20, textAlign: 'center', textDecorationLine: 'underline'}}>
                  <label>No Pendaftaran</label>
                </div>
                <hr style={{border:'none', height:1, backgroundColor:'black'}}/>
                <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:20, marginTop:20, textAlign: 'center', textDecorationLine: 'underline'}}>
                  <label>{this.state.year + '-' + this.state.registration_type_id + '-' + this.state.registration_number}</label>
                </div>
              </div>


              <div style={{width:120, height:160, border: '1px solid black', margin:20, marginLeft: 400}}>
                  {this.state.image_64?
                  <Image alt="Foto" style={{width: '100%', height: '100%'}} src={this.state.image_64} thumbnail />
                  :''}
              </div>
            </div>

            <div >
              <div style={{ margin:5, marginLeft:50, marginTop:20}}>
                <label>Panitia Penerimaan Peserta Didik Baru SMP DEMO menyatakan bahwa :</label>
              </div>
            </div> 

            <div className="rowHorizontal">
              <div style={{width:200, margin:5, marginLeft:50}}>
                <label>Nama</label>
              </div>
              <div style={{margin:5}}>
                <label>: {this.state.fullname}</label>
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{width:200, margin:5, marginLeft:50}}>
                <label>SD Asal</label>
              </div>
              <div style={{margin:5}}>
                <label>: {this.state.school_from_name}</label>
              </div>
            </div>


            <div >
              <div style={{ margin:5, marginLeft:50, marginTop:5, textAlign:'center'}}>
                <label style={{fontSize:18}}>DITERIMA</label>
              </div>
              <div style={{ margin:5, marginLeft:50, marginTop:5}}>
                <label>sebagai calon peserta didik SMP DEMO kelas VII Tahun Pelajaran 2021/2022</label>
              </div>
            </div>    

            <div >
              <div style={{ margin:5, marginLeft:50, marginTop:10}}>
                <label>Kami mengundang Bapak/Ibu untuk hadir pada dua agenda finansial berikut ini :</label>
              </div>
            </div> 

            {this.state.registration_type_id==='JU' && this.state.is_reserve==='1'?
            <div>  
              <div className="rowHorizontal" style={{ margin:3, marginLeft:120}}>
                  <div style={{width:200}}><label>Hari, tanggal</label></div>
                  <div> <label>: {moment(this.state.schedule[0].schedule_interview_at, 'YYYY-MM-DD HH:mm:ss').format('dddd, DD MMMM YYYY ')}</label></div>
              </div>
              <div className="rowHorizontal" style={{ margin:3, marginLeft:120}}>
                  <div style={{width:200}}><label>Waktu</label></div>
                  <div><label>: {moment(this.state.schedule[0].schedule_interview_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm ')}</label></div>
              </div>
              <div className="rowHorizontal" style={{ margin:3, marginLeft:120}}>
                  <div style={{width:200}}><label>Tempat</label></div>
                  <div><label>: Ruang Kepala Sekolah SMP DEMO</label></div>
              </div>
            </div> 

            :

            <div>                 
            <div >
              <div style={{ margin:3, marginLeft:80, }}>
                <label style={{fontSize:16}}>A. Sosialisasi Wawancara Finansial</label>
              </div>
              <div className="rowHorizontal" style={{ margin:3, marginLeft:120}}>
                  <div style={{width:200}}><label>Hari, tanggal</label></div>
                  <div><label>: {moment(this.state.schedule[0].schedule_socialization_at, 'YYYY-MM-DD HH:mm:ss').format('dddd, DD MMMM YYYY ')}</label></div>
              </div>
              <div className="rowHorizontal" style={{ margin:3, marginLeft:120}}>
                  <div style={{width:200}}><label>Waktu</label></div>
                  <div><label>: 16.00 WIB - selesai</label></div>
              </div>
              <div className="rowHorizontal" style={{ margin:3, marginLeft:120}}>
                  <div style={{width:200}}><label>Tempat</label></div>
                  <div><label>: Aula SMP DEMO</label></div>
              </div>
            </div> 
            
            <div >
              <div style={{ margin:3, marginLeft:80 }}>
                <label style={{fontSize:16}}>B. Wawancara Finansial</label>
              </div>
            </div>
            
            
              
            <div>  
              <div className="rowHorizontal" style={{ margin:3, marginLeft:120}}>
                  <div style={{width:200}}><label>Hari, tanggal</label></div>
                  <div><label>: {moment(this.state.schedule[0].schedule_interview_at, 'YYYY-MM-DD HH:mm:ss').format('dddd, DD MMMM YYYY ')}</label></div>
              </div>
              <div className="rowHorizontal" style={{ margin:3, marginLeft:120}}>
                  <div style={{width:200}}><label>Waktu</label></div>
                  <div><label>: {moment(this.state.schedule[0].schedule_interview_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm ')}</label></div>
              </div>
              <div className="rowHorizontal" style={{ margin:3, marginLeft:120}}>
                  <div style={{width:200}}><label>Tempat</label></div>
                  <div><label>: Aula SMP DEMO</label></div>
              </div>
            </div>
            </div>
            
            }



             
             
             {/* */}
             
             
            

            <div >
              <div style={{ margin:5, marginLeft:50, marginTop:10}}>
                <label>Jika pada hari yang telah ditentukan di atas, Bapak/Ibu tidak datang, kami nyatakan calon peserta didik mengundurkan diri dan tempat akan kami berikan kepada calon peserta didik lain.</label>
              </div>
            </div>   

            <div >
              <div style={{ margin:5, marginLeft:50, marginTop:10}}>
                <label>Demikian surat keputusan dan undangan kami, atas perhatian Bapak/Ibu kami ucapkan terima kasih.</label>
              </div>
            </div>  

            <div className="rowHorizontal">
              <div style={{ margin:5, marginLeft:550}}>
                <label>Jakarta, {moment(this.state.created_at, 'YYYY-MM-DD').format('DD-MM-YYYY')}</label>
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{ margin:5, marginLeft:550, marginTop:20}}>
                <label>PANITIA PPDB</label>
              </div>
            </div>

            <div >
              <div style={{ margin:5, marginLeft:50, marginTop:20}}>
                <label style={{fontStyle:'italic'}}>*Undangan ini harus dibawa pada saat sosialisasi dan wawancara finansial.</label>
              </div>
            </div>
            
        </div>
        : ''
    );
  }
}

class Cetak extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
    }
  }

  
  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                          <Breadcrumb>
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Cetak Wawancara</Breadcrumb.Item>
                          </Breadcrumb>

                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>

                          
                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Cetak;